













































import { Component, Mixins } from 'vue-property-decorator';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import ContainerTutorial from '@/components/ContainerTutorial/ContainerTutorial.vue';
import Footer from '@/components/Footer/Footer.vue';

import { LIST_ONBOARDING_ID } from '@/constant/OnboardingIDS';
import { DATA_TUTORIAL_DOUBTS } from './TutorialsDoubts';

import { OnboardingClass } from '@/mixins/Onboarding';
import { NavigationTutorials } from '@/mixins/NavigationTutorials';

const NUMBER_CONTENT_WIDTH_LARGE = 1139;

@Component({
  components: {
    PageHeader,
    ContainerTutorial,
    Footer
  }
})
export default class TutorialDoubts extends Mixins(OnboardingClass, NavigationTutorials) {
  private isLoading = false;

  created() {
    this.saveDataTutorials(DATA_TUTORIAL_DOUBTS);
  }

  mounted() {
    this.setBreadCrumbs();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get setPositionFooter() {
    return this.$responsive.contentwidth >= NUMBER_CONTENT_WIDTH_LARGE;
  }

  async updateOnboardingView() {
    try {
      this.isLoading = true;
      await this.updateOnboarding(LIST_ONBOARDING_ID.DOUBTS);
      await this.timerUpdateProfile(this.goToDoubtsPage);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  goToDoubtsPage() {
    this.$router.push({
      name: 'Doubts'
    });
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([
      { title: 'Dúvidas ', to: '/doubts' },
      { title: 'Tutorial ', to: null }
    ]);
  }
}
